























































import { Component, Vue } from "vue-property-decorator";
import { ITicketProfile, ITicketProfileUpdate } from "@/interfaces";
import {
  dispatchGetTickets,
  dispatchUpdateTicket,
} from "@/store/ticket/actions";
import { readAdminTicket } from "@/store/ticket/getters";

@Component
export default class EditTicket extends Vue {
  public valid = true;
  public title: string = "";
  public batches: string = "";

  public channel: string = "";
  public nickName: string = "";

  public defTicketStatus = [
    {
      text: "等待处理",
      value: "waiting",
    },
    {
      text: "取消",
      value: "cancel",
    },
    {
      text: "处理完成",
      value: "finished",
    },
  ];

  public status: any = this.defTicketStatus[0];

  public defCategories = [
    {
      text: "眼科筛查",
      value: "eye-screening",
    },
    {
      text: "其他检查",
      value: "other",
    },
  ];

  public category: any = this.defCategories[0];

  public async mounted() {
    await dispatchGetTickets(this.$store, null);
    this.reset();
  }

  public reset() {
    this.$validator.reset();
    if (this.ticket) {
      this.title = this.ticket.title;
      this.batches = this.ticket.batches;
      this.channel = this.ticket.channel;
      this.nickName = this.ticket.owner;
      this.category.value = this.ticket.category;
      this.status = this.ticket.status;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: ITicketProfileUpdate = {
        status: this.status.value,
      };
      if (this.title) {
        updatedProfile.title = this.title;
      }

      if (this.nickName) {
        updatedProfile.owner = this.nickName;
      }

      await dispatchUpdateTicket(this.$store, {
        id: this.ticket!.id,
        ticket: updatedProfile,
      });
      this.$router.push("/main/admin/tickets/all");
    }
  }

  get ticket() {
    return readAdminTicket(this.$store)(+this.$router.currentRoute.params.id);
  }
}

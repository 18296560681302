































import { Component, Vue } from 'vue-property-decorator';
import {
  ICompanyProfile,
  ICompanyProfileUpdate,
  ICompanyProfileCreate,
} from '@/interfaces';
import { dispatchGetCompanies, dispatchCreateCompany } from '@/store/company/actions';

@Component
export default class CreateCompany extends Vue {
  public valid = false;
  public fullName: string = '';
  public title: string = '';
  public isActive: boolean = true;
  public address: string = '';
  public position: string = '';
  public tel: string = '';

  public async mounted() {
    await dispatchGetCompanies(this.$store);
    this.reset();
  }

  public reset() {
    this.title = '';
    this.address = '';
    this.fullName = '';
    this.position = '';
    this.isActive = true;
    this.tel = '';
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: ICompanyProfileCreate = {
        title: this.title,
      };
      if (this.fullName) {
        updatedProfile.full_name = this.fullName;
      }
      if (this.address) {
        updatedProfile.address = this.address;
      }
      updatedProfile.is_active = this.isActive;
      updatedProfile.position = this.position;
      updatedProfile.address = this.address;
      updatedProfile.tel = this.tel;
      await dispatchCreateCompany(this.$store, updatedProfile);
      this.$router.push('/main/admin/companies/all');
    }
  }
}

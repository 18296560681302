







































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IDomainProfile } from '@/interfaces';
import { readAdminDomains } from '@/store/domain/getters';
import { dispatchGetDomains, dispatchRemoveDomain } from '@/store/domain/actions';

@Component
export default class AdminDomains extends Vue {
  public headers = [
    {
      text: '公众号名称',
      sortable: true,
      value: 'title',
      align: 'left',
    },
    {
      text: '域名',
      value: 'domain',
      align: 'left',
    },
    {
      text: 'appid',
      sortable: true,
      value: 'appid',
      align: 'left',
    },
    {
      text: 'token',
      value: 'token',
      align: 'left',
    },
    {
      text: 'key',
      sortable: true,
      value: 'aesKey',
      align: 'left',
    },
    {
      text: '是否启用',
      sortable: true,
      value: 'isActive',
      align: 'left',
    },
    {
      text: '操作',
      value: 'id',
      align: 'center',
    },
  ];
  get domains() {
    return readAdminDomains(this.$store);
  }

  public async mounted() {
    console.log('domains mounted....');
    await dispatchGetDomains(this.$store);
  }

  public async deleteById(domainId) {
    const res = 'OK';

    // await this.$dialog.confirm({
    //   text: 'Do you really want to exit?',
    //   title: 'Warning'
    // })
    console.log('res: ' + res);
    if (res === 'OK' ) {
      await dispatchRemoveDomain(this.$store, domainId);
      // this.$router.push('/main/admin/domains/all');
    }
  }
}

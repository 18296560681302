
























































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Store } from "vuex";
import { api } from "@/api";
import { appName } from "@/env";

import { IQueryResultProf } from "@/interfaces";

import { readLoginError } from "@/store/main/getters";
import { dispatchLogIn } from "@/store/main/actions";

import { dispatchGetUnits } from "@/store/unit/actions";
import { readSchools } from "@/store/unit/getters";

import { dispatchGetBatches } from "@/store/batches/actions";
import { readAdminBatches } from "@/store/batches/getters";
import { dispatchCreateTicket } from "@/store/ticket/actions";

@Component
export default class WxQuery extends Vue {
  public valid = false;
  public school: any = { vaule: "", text: "" };
  public grade: string = "";
  public fullName: string = "";
  public appName = appName;
  public error: boolean = false;

  public grades: any = null;
  public respQuery: any = null;

  public fullNameRules = [
    (v) => !!v || "请输入姓名",
    (v) => (v && v.length <= 32) || "姓名长度不能大于32",
  ];

  public get shools() {
    // return readAdminUsers(this.$store);
    let units = readSchools(this.$store);
    const schools = units.filter((unit) => unit.category === "school");

    let results = Array();
    for (let entry of schools) {
      results.push({ text: entry.title, value: entry.id });
    }
    console.info("school: ", units, results);
    return results;
  }

  public get batches() {
    // return readAdminUsers(this.$store);
    let allBatches = readAdminBatches(this.$store);

    let results = Array();
    for (let entry of allBatches) {
      results.push(entry.title);
    }

    console.info("batches: ", allBatches, results);
    return results;
  }

  @Watch("school")
  public async onChangeSearch(newVal: any, oldVal: any) {
    console.info("watch school: ", newVal);
    const self = this;

    await new Promise(async (resolve, reject) => {
      const resp = await api.getGroupsOfUnit(newVal.value);
      console.log("grade resp", resp.data);
      let grades = Array();

      for (let entry of resp.data) {
        grades.push(entry.title);
      }
      console.log("grades", grades);
      self.grades = grades;
      // resolve(resp);
    }).catch(function (err) {
      console.log(" error", err.message); // never called
    });
  }

  @Watch("fullName")
  public async onChangeFullName(newVal: any, oldVal: any) {
      this.error = false;
  }

  public get vertifyError() {
    // return readLoginError(this.$store);
    return false;
  }

  public async submit() {
      this.error = false;

    if (await this.$validator.validateAll()) {
      let queryProf: IQueryResultProf = {
        channel: this.school.text,
        group: this.grade,
        owner: this.fullName,
      };
    
      console.info(queryProf);

      const self = this;

      await new Promise(async (resolve, reject) => {
          try {
            const resp = await api.queryResult(queryProf);
            console.log("result resp", resp.data);

            self.respQuery = resp.data;
          } catch (error) {
              console.log("error resp", error);
              self.error = true;

           };
        // resolve(resp);
      }).catch(function (err) {
        console.log(" error", err.message); // never called
      });
    }
  }

  public async mounted() {
    await dispatchGetUnits(this.$store);

    console.info("input query loading ...");
  }
}





































































import { Component, Vue } from 'vue-property-decorator';
import { ICompanyProfile, ICompanyProfileUpdate } from '@/interfaces';
import { dispatchGetCompanies, dispatchUpdateCompany } from '@/store/company/actions';
import { readAdminOneCompany } from '@/store/company/getters';

@Component
export default class EditCompany extends Vue {
  public valid = true;
  public fullName: string = '';
  public title: string = '';
  public isActive: boolean = true;
  public address: string = '';
  public position: string = '';
  public tel: string = '';

  public async mounted() {
    await dispatchGetCompanies(this.$store);
    this.reset();
  }

  public reset() {

    this.$validator.reset();
    if (this.company) {
      this.title = this.company.title;
      this.address = this.company.address;
      this.fullName = this.company.full_name;
      this.position = this.company.position;
      this.isActive = this.company.is_active;
      this.tel = this.company.tel;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: ICompanyProfileUpdate = {};
      if (this.title) {
        updatedProfile.title = this.title;
      }
      if (this.fullName) {
        updatedProfile.full_name = this.fullName;
      }
      updatedProfile.is_active = this.isActive;
      updatedProfile.address = this.address;
      updatedProfile.position = this.position;
      updatedProfile.tel = this.tel;
      await dispatchUpdateCompany(
        this.$store, { id: this.company!.id, company: updatedProfile });
      this.$router.push('/main/admin/companies/all');
    }
  }

  get company() {
    return readAdminOneCompany(this.$store)(+this.$router.currentRoute.params.id);
  }
}



































import {
    Component,
    Vue,
} from 'vue-property-decorator';
import {
    IMemberProfile,
    IMemberProfileUpdate,
    IMemberProfileCreate,
} from '@/interfaces';
import {
    dispatchGetMembers,
    dispatchCreateMember,
} from '@/store/member/actions';

@Component
export default class CreateMember extends Vue {
    public valid = false;
    public login: string = '';
    public nickName: string = '';
    public passwd: string = '123456';
    public isActive: boolean = true;

    public profile: any = {};

    public organizationRules = [
        (v) => !!v || 'login is required',
        (v) => (v && v.length <= 32) || 'login must be less than 32 characters',
    ];

    public async mounted() {
        await dispatchGetMembers(this.$store);
        this.reset();
    }

    public reset() {
        this.login = '';
        this.nickName = '';
        this.passwd = '123456';
        this.profile = {};
        this.isActive = true;
        this.$validator.reset();
    }

    public cancel() {
        this.$router.back();
    }

    public async submit() {
        if (await this.$validator.validateAll()) {
            const updatedProfile: IMemberProfileCreate = {
                login: this.login,
                nick_name: this.nickName,
            };

            if (this.passwd) {
                updatedProfile.token = this.passwd;
            }

            updatedProfile.is_active = this.isActive;

            updatedProfile.profile = {};

            await dispatchCreateMember(this.$store, updatedProfile);
            this.$router.push('/main/admin/members/all');
        }
    }
}

































import { Component, Vue } from 'vue-property-decorator';
import {
  IDomainProfile,
  IDomainProfileUpdate,
  IDomainProfileCreate,
} from '@/interfaces';
import { dispatchGetDomains, dispatchCreateDomain } from '@/store/domain/actions';

@Component
export default class CreateDomain extends Vue {
  public valid = false;
  public title: string = '';
  public domainHost: string = '';
  public appid: string = '';
  public token: string = '';
  public aesKey: string = '';
  public isActive: boolean=true;
  
  public async mounted() {
    await dispatchGetDomains(this.$store);
    this.reset();
  }

  public reset() {
    this.title = '';
    this.domainHost = '';
    this.appid = '';
    this.token = '';
    this.aesKey = '';
    this.isActive = true;
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      let updatedProfile: IDomainProfileCreate = {
        title: this.title,
        domain: this.domainHost,
      };
      
      if (this.appid) {
        updatedProfile.appid = this.appid;
      }
      if (this.token) {
        updatedProfile.token = this.token;
      }
      if (this.aesKey) {
        updatedProfile.aes_key = this.aesKey;
      }

      updatedProfile.is_active = this.isActive;

      await dispatchCreateDomain(this.$store, updatedProfile);
      this.$router.push('/main/admin/domains/all');
    }
  }
}

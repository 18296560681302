







































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { ICompanyProfile } from '@/interfaces';
import { readAdminCompanies } from '@/store/company/getters';
import { dispatchGetCompanies, dispatchRemoveCompany } from '@/store/company/actions';

@Component
export default class AdminCompanies extends Vue {
  public headers = [
    {
      text: '简称',
      sortable: true,
      value: 'title',
      align: 'left',
    },
    {
      text: '全名',
      sortable: true,
      value: 'full_name',
      align: 'left',
    },
    {
      text: '地址',
      sortable: true,
      value: 'address',
      align: 'left',
    },
    {
      text: '位置',
      sortable: true,
      value: 'position',
      align: 'left',
    },
    {
      text: '电话',
      sortable: true,
      value: 'tel',
      align: 'left',
    },
    {
      text: '是否启用',
      sortable: true,
      value: 'isActive',
      align: 'left',
    },
    {
      text: '操作',
      value: 'id',
      align: 'center',
    },
  ];
  get companies() {
    // return readAdminUsers(this.$store);
    return readAdminCompanies(this.$store);
  }

  public async mounted() {
    // await dispatchGetUsers(this.$store);
    console.log('company mounted....');
    await dispatchGetCompanies(this.$store);
  }

  public async deleteById(companyId) {
    const res = 'OK';

    // await this.$dialog.confirm({
    //   text: 'Do you really want to exit?',
    //   title: 'Warning'
    // })
    console.log('res: ' + res);
    if (res === 'OK' ) {
      await dispatchRemoveCompany(this.$store, companyId);
      // this.$router.push('/main/admin/companies/all');
    }
  }
}

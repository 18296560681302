





































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IBatchProfile } from '@/interfaces';
import { readAdminBatches } from '@/store/batches/getters';
import { dispatchGetBatches, dispatchRemoveBatch } from '@/store/batches/actions';

@Component
export default class AdminBatches extends Vue {
  public headers = [
    {
      text: '简称',
      sortable: true,
      value: 'title',
      align: 'left',
    },
    {
      text: '批次',
      sortable: true,
      value: 'batches',
      align: 'left',
    },
    {
      text: '起始日',
      sortable: true,
      value: 'at_open',
      align: 'left',
    },
    {
      text: '截止日',
      sortable: true,
      value: 'at_close',
      align: 'left',
    },
    {
      text: '操作',
      value: 'id',
      align: 'center',
    },
  ];
  get batches() {
    // return readAdminUsers(this.$store);
    return readAdminBatches(this.$store);
  }

  public async mounted() {
    // await dispatchGetUsers(this.$store);
    console.log('batches mounted....');
    await dispatchGetBatches(this.$store);
  }

  public async deleteById(batchId) {
    const res = 'OK';

    // await this.$dialog.confirm({
    //   text: 'Do you really want to exit?',
    //   title: 'Warning'
    // })
    console.log('res: ' + res);
    if (res === 'OK' ) {
      await dispatchRemoveBatch(this.$store, batchId);
      // this.$router.push('/main/admin/companies/all');
    }
  }
}

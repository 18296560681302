


































import {
    Component,
    Vue
} from 'vue-property-decorator';
import {
    IDomainProfile,
    IDomainProfileUpdate
} from '@/interfaces';
import {
    dispatchGetDomains,
    dispatchUpdateDomain
} from '@/store/domain/actions';
import {
    readAdminDomain
} from '@/store/domain/getters';

@Component
export default class EditDomain extends Vue {
    public valid = true;
    public title = '';
    public domainHost = '';
    public appid = '';
    public token = '';
    public aesKey = '';
    public isActive = true;

    public async mounted() {
        await dispatchGetDomains(this.$store);
        this.reset();
    }

    public reset() {
        this.$validator.reset();
        if (this.domain) {
            this.title = this.domain.title;
            this.domainHost = this.domain.domain;
            this.appid = this.domain.appid;
            this.token = this.domain.token;
            this.aesKey = this.domain.aes_key;
            this.isActive = this.domain.is_active;
        }
    }

    public cancel() {
        this.$router.back();
    }

    public async submit() {
        if (await this.$validator.validateAll()) {
            const updatedProfile: IDomainProfileUpdate = {};

            if (this.title) {
                updatedProfile.title = this.title;
            }

            if (this.domainHost) {
                updatedProfile.domain = this.domainHost;
            }

            if (this.appid) {
                updatedProfile.appid = this.appid;
            }
            if (this.token) {
                updatedProfile.token = this.token;
            }
            if (this.aesKey) {
                updatedProfile.aes_key = this.aesKey;
            }

            updatedProfile.is_active = this.isActive;

            console.log(updatedProfile);

            await dispatchUpdateDomain(
                this.$store, {
                    id: this.domain!.id,
                    domain: updatedProfile,
                });
            this.$router.push('/main/admin/domains/all');
        }
    };

    get domain() {
        return readAdminDomain(this.$store)(+this.$router.currentRoute.params.id);
    }
}


















































import { Component, Vue } from "vue-property-decorator";
import {
  ITicketProfile,
  ITicketProfileUpdate,
  ITicketProfileCreate,
} from "@/interfaces";
import {
  dispatchGetTickets,
  dispatchCreateTicket,
} from "@/store/ticket/actions";

@Component
export default class CreateTicket extends Vue {
  public valid = false;
  public title: string = "";
  public batches: string = "";
  public channel: string = "";
  public owner: string = "";

  public defTicketStatus = [
    {
      text: "等待处理",
      value: "waiting",
    },
    {
      text: "取消",
      value: "cancel",
    },
    {
      text: "处理完成",
      value: "finished",
    },
  ];

  public status: any = this.defTicketStatus[0];

  public defCategories = [
    {
      text: "眼科筛查",
      value: "eye-screening",
    },
    {
      text: "其他检查",
      value: "other",
    },
  ];

  public category: any = this.defCategories[0];

  public async mounted() {
    await dispatchGetTickets(this.$store, "*");
    this.reset();
  }

  public reset() {
    this.title = "";
    this.batches = "";
    this.channel = "";
    this.owner = "";
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      let updatedProfile: ITicketProfileCreate = {
        title: this.title,
      };

      if (this.batches) {
        updatedProfile.batches = this.batches;
      }

      if (this.channel) {
        updatedProfile.channel = this.channel;
      }
      if (this.owner) {
        updatedProfile.owner = this.owner;
      }
      if (this.status) {
        updatedProfile.status = this.status.value;
      }
      if (this.category) {
        updatedProfile.category = this.category.value;
      }

      await dispatchCreateTicket(this.$store, updatedProfile);
      this.$router.push("/main/admin/tickets/all");
    }
  }
}
























































import { Component, Vue } from 'vue-property-decorator';
import { IUnitProfile, IUnitProfileUpdate } from '@/interfaces';
import { dispatchGetUnits, dispatchUpdateUnit } from '@/store/unit/actions';
import { readAdminUnit } from '@/store/unit/getters';

@Component
export default class EditUnit extends Vue {
  public categories = [{text:'学校', value:'school'}, {text:'其它',value:'other'}];

  public valid = true;
  public category: any = {text:'学校', value:'school'};
  public title: string = '';
  public isActive: boolean = true;

  public async mounted() {
    await dispatchGetUnits(this.$store);
    this.reset();
  }

  public reset() {
    this.$validator.reset();
    if (this.unit) {
      this.title = this.unit.title;
      
      for ( let i in this.categories ) {
        if ( this.categories[i].value == this.unit.category ) {
          this.category = this.categories[i];
        }
      }
      // this.category = this.unit.category;

      this.isActive = this.unit.is_active;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: IUnitProfileUpdate = {};
      if (this.title) {
        updatedProfile.title = this.title;
      }
      if (this.category) {
        updatedProfile.category = this.category.value;
      }
      updatedProfile.is_active = this.isActive;

      await dispatchUpdateUnit(
        this.$store, { id: this.unit!.id, unit: updatedProfile });
      this.$router.push('/main/admin/units/all');
    }
  }

  get unit() {
    return readAdminUnit(this.$store)(+this.$router.currentRoute.params.id);
  }
}

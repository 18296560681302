




















































import { Component, Vue } from 'vue-property-decorator';
import {
  IBatchProfile,
  IBatchProfileUpdate,
  IBatchProfileCreate,
} from '@/interfaces';
import { dispatchGetBatches, dispatchCreateBatch } from '@/store/batches/actions';

@Component
export default class CreateBatch extends Vue {
  public valid = false;
  public batches: string = '';
  public title: string = '';
  public menu1 = false;
  public menu2 = false;

  public atOpen: string = new Date().toISOString().substr(0, 10);
  public atClose: string = '';

  public async mounted() {
    await dispatchGetBatches(this.$store);
    this.reset();
  }

  public reset() {
    this.title = '';
    this.batches = '';
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: IBatchProfileCreate = {
        title: this.title,
      };
      if (this.batches) {
        updatedProfile.batches = this.batches;
      }
      if (this.atOpen) {
        updatedProfile.at_open = this.atOpen;
      }

      updatedProfile.at_close = this.atClose;

      await dispatchCreateBatch(this.$store, updatedProfile);
      this.$router.push('/main/admin/batches/all');
    }
  }
}
